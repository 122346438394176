<template lang="pug">
  #warning
    .modal-header
      h2 {{ warningTitle }}

    .modal-text
      icon#warning-icon(:data="iconPath")
      p {{ warningText }}

    .modal-footer
      button.button.action-btn(v-if="cancelBtn", @click="close") Cancel
      button.button.action-btn(@click="acknowledge", :class="buttonType") {{ ackBtnText }}
</template>

<script>
export default {
  props: {
    warningTitle: { type: String, required: false, default: 'Warning' },
    warningText: { type: String, required: true },
    ackBtnText: { type: String, required: true },
    canClose: { type: Boolean, required: false, default: true },
    callBack: { type: Function, required: false },
    icon: { type: String, required: false, default: 'warning.svg' },
    cancelBtn: { type: Boolean, required: false, default: false },
    buttonType: { type: String, required: false, default: '' }
  },

  data() {
    return {
      iconPath: null,
    }
  },

  computed: {},

  watch: {},

  created() {
    this.iconPath = require('@icon/' + this.icon)
  },

  mounted() {},

  methods: {
    acknowledge,
    close,
  },

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function acknowledge() {
  if (this.canClose) {
    this.$modal.close('Warning')
  }

  if (this.callBack) {
    this.callBack()
  }
}
function close() {
    this.$modal.close('Warning')
  }
</script>
